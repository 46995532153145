<template lang="pug">
  estimate-form(
    v-model='estimate',
    submit-label='Add estimate',
    :saving='saving',
    @save='createEstimate',
    @cancel='cancelCreation')
</template>

<script>
import createEstimateMutation from "@/graphql/mutations/finance/estimates/CreateEstimate.gql";
import createEstimateFilter from "@/graphql/mutations/filters/CreateEstimateFilter";
import mutate from "@/components/utils/Mutator";
import EstimateForm from "./Form.vue";

export default {
  components: {
    EstimateForm
  },
  data() {
    const data = {
      saving: false,
      estimate: {
        status: "DRAFT",
        currency: this.$store.state.defaultCurrency,
        lineItems: [{ key: 0 }, { key: 1 }, { key: 2 }]
      }
    };

    if (this.$route.params.id) {
      data.estimate.project = {
        id: this.$route.params.id
      };
    }

    return data;
  },
  methods: {
    createEstimate() {
      const vm = this;
      this.saving = true;

      return mutate(this, {
        mutation: createEstimateMutation,
        variables: {
          input: {
            ...createEstimateFilter(this.estimate)
          }
        }
      })
        .then(data => {
          window.location.assign(
            `/estimates/${data.data.createEstimate.estimate.id}`
          );
        })
        .then(() => {
          vm.saving = false;
        });
    },
    cancelCreation() {
      window.location.assign("/finances/#estimates");
    }
  }
};
</script>
