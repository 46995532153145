<template lang="pug">
  page-layout(:title='$route.name', :loading='loading || !estimate')
    finance-form(
      :structure='formStructure',
      v-model='estimate',
      :submit-label='submitLabel',
      modelName='estimate',
      @save='saveEstimate',
      @cancel='$emit("cancel")',
      @input='$emit("input", $event)',
      :v='$v',
      :loading='loading',
      :saving='saving')
</template>

<script>
import {
  helpers,
  required,
  requiredIf,
  requiredUnless,
  decimal
} from "vuelidate/lib/validators";
import isNil from "ramda/src/isNil";
import isEmpty from "ramda/src/isEmpty";
import clone from "ramda/src/clone";
import contains from "ramda/src/contains";
import FinanceForm from "@/views/finances/Form.vue";
import PageLayout from "@/views/common/PageLayout.vue";
import scrollToError from "@/components/utils/ScrollToError";
import gdvCalculationsEnabled from "@/graphql/queries/core/company/GDVCalculationsEnabled.gql";

const lineItemRequiredValidation = requiredUnless(
  nestedModel => isNil(nestedModel.item) || isEmpty(nestedModel.item)
);

export default {
  components: {
    FinanceForm,
    PageLayout
  },
  props: {
    value: Object,
    loading: Boolean,
    saving: Boolean,
    submitLabel: String
  },
  apollo: {
    company: {
      query: gdvCalculationsEnabled,
      update(data) {
        if (data.company.features.gdvCalculations) {
          this.formStructure.detailFields.push({
            label: "GDV",
            name: "gdv",
            type: "money",
            help: "finance.estimates.form.gdv",
            placeholder: "Your estimated GDV"
          });

          this.formStructure.calculateProfit = true;
        }
      }
    }
  },
  data() {
    return {
      estimate: clone(this.value),
      formStructure: {
        resourceName: "Estimate",
        calculateProfit: false,
        keyFields: [
          {
            label: "Estimate name",
            name: "name",
            type: "input",
            placeholder: "Name this estimate",
            required: true,
            help: "finance.estimates.form.name"
          },
          { label: "Estimate for", name: "contact", type: "contact" }
        ],
        detailFields: [
          { label: "Estimate number", name: "number", type: "sequence" },
          {
            label: "Currency",
            name: "currency",
            type: "currency",
            required: true
          },
          {
            label: "Exchange rate",
            name: "exchangeRate",
            type: "exchangeRate",
            required: true
          }
        ],
        descriptionField: {
          placeholder: "Write a description to the contact...",
          name: "description"
        },
        lineItems: {
          type: "markup",
          task: true,
          description: false
        }
      }
    };
  },
  validations: {
    estimate: {
      name: {
        required
      },
      exchangeRate: {
        decimal,
        required: requiredIf(function notBaseCurrency(model) {
          return model.currency.id !== this.$store.state.defaultCurrency.id;
        })
      },
      lineItems: {
        $each: {
          quantity: {
            required: lineItemRequiredValidation,
            decimal
          },
          unitCost: {
            required: lineItemRequiredValidation,
            decimal
          },
          markupType: {
            inList: value =>
              !helpers.req(value) ||
              contains(value, ["PERCENT", "AMOUNT", "AMOUNT_PER_UNIT", "NONE"])
          },
          markupAmount: {
            decimal,
            required: requiredIf(
              model =>
                !(isNil(model.item) || isEmpty(model.item)) &&
                model.markupType &&
                model.markupType !== "NONE"
            )
          }
        }
      }
    }
  },
  watch: {
    value(newValue) {
      this.estimate = clone(newValue);
    }
  },
  methods: {
    saveEstimate() {
      this.$v.estimate.$touch();
      if (this.$v.estimate.$invalid) {
        scrollToError(this);
      } else {
        this.$emit("save");
      }
    }
  }
};
</script>
