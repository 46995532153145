import path from "ramda/src/path";

export default lineItem => ({
  id: lineItem.id,
  item: {
    id: path(["item", "id"], lineItem)
  },
  task: {
    id: path(["task", "id"], lineItem),
    name: lineItem.taskName
  },
  description: lineItem.description,
  quantity: lineItem.quantity,
  unitCost: lineItem.unitCost,
  markupType: lineItem.markupType,
  markupAmount: lineItem.markupAmount
});
