import path from "ramda/src/path";
import reject from "ramda/src/reject";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import lineItemFilter from "./MarkupLineItemFilter";

export default estimate => {
  const contactId = path(["contact", "id"], estimate);
  const filtered = {
    name: estimate.name,
    number: estimate.number,
    exchangeRate: estimate.exchangeRate,
    description: estimate.description,
    contact: contactId ? { id: contactId } : null,
    gdv: estimate.gdv,
    currency: {
      id: estimate.currency.id
    },
    lineItems: reject(
      li => isEmpty(li.item.id) || isNil(li.item.id),
      estimate.lineItems.map(li => lineItemFilter(li))
    )
  };

  if (estimate.project) {
    filtered.project = { id: estimate.project.id };
  }

  return filtered;
};
